import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"
import OtherCaseStudy from "../../components/other-case"

import mokup from "../../assets/img/tanoto-mokup.png"

import melisa from "../../assets/img/teams/18.jpg"
import seher from "../../assets/img/teams/04.jpg"
import duygu from "../../assets/img/teams/36.png"
import selin from "../../assets/img/teams/45.jpg"
import muhammed from "../../assets/img/teams/44.jpg"






const ReferancesDetail = () => (
    <Layout>
        <SEO title="Tanoto Başarı Hikayesi" />
        <div className="referances-detail py60 container" style={{ maxWidth: 950}}>
            <div className="col-md-12 detail-left" >
                <b>BAŞARI HİKAYESİ</b>
                <h1>Tanoto</h1>
                <p style={{fontSize: 22 }}>Otomotiv sektöründe hizmet veren Ankara'nın köklü markası Tanoto'nun sosyal medya hesaplarında organik olarak erişim artışı markamızın talepleri arasındaydı. Biz de Instagram'da kullanıcıların yoğunlaştığı, ilginin her geçen gün arttığı Reels özelliğinde görünür olmaya karar verdik.</p>
            </div>
            <div className="col-md-6 detail-left">
                <div className="provided">
                    <h3>Verilen Hizmetler</h3>
                    <div className="provided-box">
                        <div className="box">
                            <Link to="/digital-ads/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Shopping-_-Cart_1" data-name="Icons-/-Shopping-/-Cart#1" transform="translate(0.452 0.373)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.452 -0.373)" fill="none" />
                                    <path id="Combined-Shape" d="M52.453,31.233l-2.2,13.227-.008.046a3.161,3.161,0,0,1-3.675,2.544L10.63,40.517a3.161,3.161,0,0,1-2.474-2.241L2.389,18.088a3.161,3.161,0,0,1,3.039-4.029H48.813l1.938-8.306a3.729,3.729,0,0,1,3.632-2.882h6.527a3.729,3.729,0,1,1,0,7.459H57.342Z" transform="translate(4.899 6.203)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Combined-Shape-2" data-name="Combined-Shape" d="M9.741,27.482a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,9.741,27.482Zm28.447,0a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,38.188,27.482Z" transform="translate(10.804 38.894)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            Digital Ads
                        </div>
                        <div className="box">
                            <Link to="/sosyal-medya/"></Link>
                            <svg id="instagram-logo" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 61.312 61.312">
                                <path id="Path_36346" data-name="Path 36346" d="M44.392,0H16.92A16.939,16.939,0,0,0,0,16.92V44.392a16.939,16.939,0,0,0,16.92,16.92H44.392a16.939,16.939,0,0,0,16.92-16.92V16.92A16.939,16.939,0,0,0,44.392,0ZM55.872,44.392a11.493,11.493,0,0,1-11.48,11.48H16.92A11.493,11.493,0,0,1,5.44,44.392V16.92A11.493,11.493,0,0,1,16.92,5.44H44.392a11.493,11.493,0,0,1,11.48,11.48V44.392Z" />
                                <path id="Path_36347" data-name="Path 36347" d="M56.767,40.97a15.8,15.8,0,1,0,15.8,15.8A15.816,15.816,0,0,0,56.767,40.97Zm0,26.156A10.358,10.358,0,1,1,67.125,56.768,10.37,10.37,0,0,1,56.767,67.126Z" transform="translate(-26.111 -26.112)" />
                                <path id="Path_36348" data-name="Path 36348" d="M122.91,28.251a3.987,3.987,0,1,0,2.821,1.168A4,4,0,0,0,122.91,28.251Z" transform="translate(-75.793 -18.005)" />
                            </svg>
                            Sosyal Medya
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="col-md-6 project-details">
                    <h3>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Marka Stratejisti:</li>
                            <li>Marka Yöneticisi:</li>
                            <li>Proje Sorumlusu:</li>
                            <li>Sosyal Medya Yöneticisi:</li>
                            <li>Digital Tasarımcı:</li>
                        </ul>
                        <ul>
                            <li><img src={melisa}  alt="Melisa Akınsa"/>Melisa Akınsal</li>
                            <li><img src={duygu} alt="Dilara Karakuş" />Dilara Karakuş</li>
                            <li><img src={seher} alt="Seher Taylan" />Seher Taylan</li>
                            <li><img src={selin} alt="Selin Meracı" />Selin Meracı</li>
                            <li><img src={muhammed} alt="Muhammed Ergün" />Muhammed Ergün</li>
                        </ul>
                    </div>

            </div>
        </div>
     

        <div className="container">
            <div className="text-center">
                <p className="small" style={{color:"#ea4f6a" }} >Başarı Hikayesi</p>
                <p style={{ fontSize: 22, margin: '20px auto 30px', maxWidth: 900 }}>Trend müzik ve akımlara uygun olarak ürettiğimiz Reels videolarımızla 8 aylık süreçte 19 video ile <b>hiç reklam harcaması yapmadan</b> (sadece Reels videoları özelinde) <b>+750.000</b> erişim sağladık. Normale göre erişimlerimizi <b>%248</b> arttırmış olduk.</p>
                <img style={{ width: '80%', margin: '0 auto' }} src={mokup} />
                <p style={{ fontSize: 22, margin: '4px auto 30px', maxWidth: 800 }}>Bu sayede hedefimizi gerçekleştirirken trendlere uyum sağlayıp keşfete düşen Reels'lerimizle <b>%30</b> daha fazla takipçi kazandık.</p>
                <p>Reels videolarını izlemek için <a href="https://www.instagram.com/tanototr/reels/" target="_blank" ><b>tıklayınız.</b></a></p>
            </div>

        </div>




        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
